import { Component } from '@angular/core';
import { ReplaceableComponentsService } from '@abp/ng.core';
import { eThemeLeptonComponents } from '@volo/abp.ng.theme.lepton';
import { CustomFooterComponent } from './shared/custom-footer/custom-footer.component';
import { SettingTabsService } from '@abp/ng.setting-management/config';
import { SmtpServerSettingsComponent } from './settings/smtp-server-settings/smtp-server-settings.component';
import { AgentUpdateSettingsComponent } from './settings/agent-update-settings/agent-update-settings.component';
import { ContinuousPstScanSettingsComponent } from './settings/continuous-pst-scan-settings/continuous-pst-scan-settings.component';

@Component({
  selector: 'app-root',
  template: `
    <abp-loader-bar></abp-loader-bar>
    <abp-dynamic-layout></abp-dynamic-layout>
    <abp-gdpr-cookie-consent></abp-gdpr-cookie-consent>
    <abp-internet-status></abp-internet-status>
  `,
})
export class AppComponent {
  constructor(
      private replaceableComponents: ReplaceableComponentsService,
      private settingTabs: SettingTabsService) {

    this.replaceableComponents.add({
      component: CustomFooterComponent,
      key: eThemeLeptonComponents.ApplicationLayoutFooter,
    });

     // added below
     settingTabs.add([
      {
        name: 'SMTP Server Settings',
        order: 101,
        requiredPolicy: 'AbpAccount.SMTPServerSettings',
        component: SmtpServerSettingsComponent,
      },
      {
        name: 'Agent Updates',
        order: 102,
        requiredPolicy: 'AbpAccount.AgentUpdateSettings',
        component: AgentUpdateSettingsComponent,
      },
      {
        name: 'Continuous PST Discovery',
        order: 103,
        requiredPolicy: 'AbpAccount.PSTScanSettings',
        component: ContinuousPstScanSettingsComponent,
      },
    ]);
  }
}
