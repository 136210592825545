import type { AgentApiKeyDto, GetAgentApiKeyInput } from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AgentApiKeyService {
  apiName = 'Default';
  

  enableDisableAgentApiByIdsAndIsEnabled = (ids: number[], isEnabled: boolean, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/agent-api-key/enable-disable-agent-api/${isEnabled}`,
      body: ids,
    },
    { apiName: this.apiName,...config });
  

  exportByInput = (input: GetAgentApiKeyInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/app/agent-api-key/export',
      params: { filterText: input.filterText, columnHeaders: input.columnHeaders, agentApiKeyTypeId: input.agentApiKeyTypeId, isEnabled: input.isEnabled, apiKey: input.apiKey, minCreatedOnUtc: input.minCreatedOnUtc, maxCreatedOnUtc: input.maxCreatedOnUtc, selectedRowIds: input.selectedRowIds, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getList = (input: GetAgentApiKeyInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<AgentApiKeyDto>>({
      method: 'GET',
      url: '/api/app/agent-api-key',
      params: { filterText: input.filterText, columnHeaders: input.columnHeaders, agentApiKeyTypeId: input.agentApiKeyTypeId, isEnabled: input.isEnabled, apiKey: input.apiKey, minCreatedOnUtc: input.minCreatedOnUtc, maxCreatedOnUtc: input.maxCreatedOnUtc, selectedRowIds: input.selectedRowIds, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  insertOrUpdateAgentApiKeyByInput = (input: AgentApiKeyDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/agent-api-key/create',
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
