<abp-modal [(visible)]="isRestoreView" [options]="{ size: 'lg' }">
  <ng-template #abpHeader>
    <h3>{{ '::RestoreView' | abpLocalization }}</h3>
  </ng-template>
  <ng-template #abpBody>

    <ngx-datatable [count]="data.totalCount" [list]="list" [offset]="list?.page" (page)="list.page = $event.offset" [rows]="data.items" default>

      <ngx-datatable-column [canAutoResize]="false" [draggable]="false" [resizeable]="false" [sortable]="false"
                            [width]="50">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <label>
            <input (click)="selectRow($event,row)" class="customRadioButton" name="selectViewRadio" type="radio"/>
            <span></span>
          </label>

        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="{{ '::Name' | abpLocalization }}" prop="name">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.name }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{ '::CreatedBy' | abpLocalization }}" prop="userName">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.userName }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="{{ '::IsPrivate' | abpLocalization }}" prop="private">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <ng-template [ngIfElse]="no" [ngIfThen]="yes" [ngIf]="row.private"></ng-template>
          <ng-template #yes let-row="row" ngx-datatable-cell-template>
            {{ 'AbpUi::Yes' | abpLocalization }}
          </ng-template>
          <ng-template #no let-row="row" ngx-datatable-cell-template>
            {{ 'AbpUi::No' | abpLocalization }}
          </ng-template>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [maxWidth]="150" [name]="'AbpUi::Actions' | abpLocalization" [sortable]="false"
                            [width]="150">
        <ng-template let-i="rowIndex" let-row="row" ngx-datatable-cell-template>
          <button (click)="deleteView(row.id)" aria-haspopup="true" class="btn btn-primary">
            <i aria-hidden="true" class="fa fa-trash mr-1"></i>
          </button>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </ng-template>
  <ng-template #abpFooter>
    <button abpClose class="btn btn-secondary" type="button">
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>

    <abp-button [disabled]="isDisabled" (click)="loadViews()">
      {{ 'AbpUi::Load' | abpLocalization }}
    </abp-button>
  </ng-template>
</abp-modal>
