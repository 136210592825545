import type { EntityName } from '../ui-saved-views/enums/entity-name.enum';
import type { PagedAndSortedResultRequestDto } from '@abp/ng.core';

export interface CreateOrEditSavedViewDto {
  id?: number;
  entity: EntityName;
  name?: string;
  private?: boolean;
  isDefault?: boolean;
  content?: string;
  createdBy?: string;
  createdOnUtc?: string;
  isDeleted?: boolean;
  deletedBy?: string;
  deletedOnUtc?: string;
  tenantId?: string;
}

export interface GetSavedViewsInput extends PagedAndSortedResultRequestDto {
  id: number;
  entity: EntityName;
}

export interface SavedViewsDto {
  savedViewsId: number;
  tenantId?: string;
  entity: EntityName;
  name?: string;
  private: boolean;
  isDefault: boolean;
  content?: string;
  createdBy?: string;
  creationDate?: string;
  isDeleted: boolean;
  deletedBy?: string;
  deletionDate?: string;
  userName?: string;
}
