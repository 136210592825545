import { ConfigStateService, CoreModule, DOM_STRATEGY } from '@abp/ng.core';
import { GdprConfigModule } from '@volo/abp.ng.gdpr/config';
import { SettingManagementConfigModule } from '@abp/ng.setting-management/config';
import { InternetConnectionStatusComponent, ThemeSharedModule } from '@abp/ng.theme.shared';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule } from '@ngxs/store';
import { CommercialUiConfigModule } from '@volo/abp.commercial.ng.ui/config';
import { AccountAdminConfigModule } from '@volo/abp.ng.account/admin/config';
import { AccountPublicConfigModule } from '@volo/abp.ng.account/public/config';
import { AuditLoggingConfigModule } from '@volo/abp.ng.audit-logging/config';
import { IdentityConfigModule } from '@volo/abp.ng.identity/config';
import { LanguageManagementConfigModule } from '@volo/abp.ng.language-management/config';
//import { registerLocale } from '@volo/abp.ng.language-management/locale';
import { SaasConfigModule } from '@volo/abp.ng.saas/config';
import { TextTemplateManagementConfigModule } from '@volo/abp.ng.text-template-management/config';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_ROUTE_PROVIDER } from './route.provider';
import { OpeniddictproConfigModule } from '@volo/abp.ng.openiddictpro/config';
import { FeatureManagementModule } from '@abp/ng.feature-management';
import { AbpOAuthModule } from '@abp/ng.oauth';
import { HttpErrorComponent, LayoutStateService, ThemeLeptonModule } from '@volo/abp.ng.theme.lepton';
import { DOMAINS_DOMAIN_ROUTE_PROVIDER } from './domains/domain/providers/domain-route.provider';
import { DOMAIN_USERS_DOMAIN_USER_ROUTE_PROVIDER } from './domain-users/domain-user/providers/domain-user-route.provider';
import { PSTFILES_PSTFILE_ROUTE_PROVIDER } from './pstfiles/pstfile/providers/pstfile-route.provider';
import { AGENTS_AGENT_ROUTE_PROVIDER } from './agents/agent/providers/agent-route.provider';
import { MAPPINGS_MAPPING_ROUTE_PROVIDER } from './mappings/mapping/providers/mapping-route.provider';
import { MIGRATION_PROCESSES_MIGRATION_PROCESS_ROUTE_PROVIDER } from './migration-processes/migration-process/providers/migration-process-route.provider';
import { AADTENANTS_AADTENANT_ROUTE_PROVIDER } from './aadtenants/aadtenant/providers/aadtenant-route.provider';
import { CENTRAL_UNC_SHARE_ROUTE_PROVIDER } from './central-unc-shares/central-unc-share/providers/central-unc-share-route.provider';
import { NETWORK_DEFINITION_ROUTE_PROVIDER } from './network-definitions/network-definition/providers/network-definition-route.provider';
import { EMAIL_TEMPLATES_ROUTE_PROVIDER } from './email-templates/email-templates/providers/email-templates-route.provider';
import { AGENT_API_KEYS_ROUTE_PROVIDER } from './agent-api-keys/agent-api-key/providers/agent-api-keys-route.provider';
import { MAPPINGPROGRESS_MAPPINGPROGRESS_ROUTE_PROVIDER } from './mapping-progress/mapping-progress/providers/mapping-progress-route.provider';
import { AGENT_UPDATE_TIER_ROUTE_PROVIDER } from './agent-update-tier/agent-update-tier/providers/agent-update-tier-route.provider';
import { CustomErrorComponent } from './shared/custom-error/custom-error-component';
import { PST_PROGRESS_OVERVIEW_ROUTE_PROVIDER } from './pst-progress-overview/providers/pst-progress-overview-route.provider';
import { MIGRATION_PROGRESS_OVERVIEW_ROUTE_PROVIDER } from './migration-progress-overview/providers/migration-progress-overview-route.provider';
import { PST_FILE_OWNER_MANAGEMENT_ROUTE_PROVIDER } from './pst-file-owner-management/providers/pst-file-owner-management-route.provider';
import { PST_FILE_EXCLUDES_ROUTE_PROVIDER } from './pst-file-excludes/providers/pst-file-excludes-route.provider';
import { AGENTS_MODULE_ROUTE_PROVIDER } from './agents/module/providers/module-route.provider';
import { FINISHED_MAPPINGS_ROUTE_PROVIDER } from './mapping-progress/finished-mappings/providers/finished-mappings-route.provider';
import { PSTFILES_PSTFILE_ITEM_ERRORS_ROUTE_PROVIDER } from './pst-item-errors/providers/pst-item-errors-route.provider';
import { registerLocale } from './register-locale';
import { SettingsModule } from './settings/settings.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale,
    }),
    AbpOAuthModule.forRoot(),
    ThemeSharedModule.forRoot({
      httpErrorConfig: {
        errorScreen: {
          component: CustomErrorComponent,
          forWhichErrors: [401, 403, 404, 500],
          hideCloseIcon: true,
        },
      },
    }),
    AccountAdminConfigModule.forRoot(),
    AccountPublicConfigModule.forRoot(),
    IdentityConfigModule.forRoot(),
    LanguageManagementConfigModule.forRoot(),
    SaasConfigModule.forRoot(),
    AuditLoggingConfigModule.forRoot(),
    OpeniddictproConfigModule.forRoot(),
    TextTemplateManagementConfigModule.forRoot(),
    SettingManagementConfigModule.forRoot(),
    
    CommercialUiConfigModule.forRoot(),
    FeatureManagementModule.forRoot(),
    GdprConfigModule.forRoot({
      privacyPolicyUrl: 'gdpr-cookie-consent/privacy',
      cookiePolicyUrl: 'gdpr-cookie-consent/cookie',
    }),
    InternetConnectionStatusComponent,
    ThemeLeptonModule.forRoot(),
    NgxsModule.forRoot([]),
    SettingsModule
  ],
  providers: [
    APP_ROUTE_PROVIDER,
    DOMAINS_DOMAIN_ROUTE_PROVIDER,
    DOMAIN_USERS_DOMAIN_USER_ROUTE_PROVIDER,
    PSTFILES_PSTFILE_ROUTE_PROVIDER,
    AGENTS_AGENT_ROUTE_PROVIDER,
    MAPPINGS_MAPPING_ROUTE_PROVIDER,
    MIGRATION_PROCESSES_MIGRATION_PROCESS_ROUTE_PROVIDER,
    AADTENANTS_AADTENANT_ROUTE_PROVIDER,
    CENTRAL_UNC_SHARE_ROUTE_PROVIDER,
    NETWORK_DEFINITION_ROUTE_PROVIDER,
    EMAIL_TEMPLATES_ROUTE_PROVIDER,
    AGENT_API_KEYS_ROUTE_PROVIDER,
    MAPPINGPROGRESS_MAPPINGPROGRESS_ROUTE_PROVIDER,
    AGENT_UPDATE_TIER_ROUTE_PROVIDER,
    PST_PROGRESS_OVERVIEW_ROUTE_PROVIDER,
    MIGRATION_PROGRESS_OVERVIEW_ROUTE_PROVIDER,
    PST_FILE_OWNER_MANAGEMENT_ROUTE_PROVIDER,
    PST_FILE_EXCLUDES_ROUTE_PROVIDER,
    AGENTS_MODULE_ROUTE_PROVIDER,
    FINISHED_MAPPINGS_ROUTE_PROVIDER,
    PSTFILES_PSTFILE_ITEM_ERRORS_ROUTE_PROVIDER,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [LayoutStateService],
      useFactory: subscribeThemeStyleLoad,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [ConfigStateService],
      useFactory: setInitialStyle,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

function subscribeThemeStyleLoad(layoutStateService: LayoutStateService) {
  return () => {
    // if (isWatchEnvironment()) {
    //   return;
    // }

    layoutStateService.patch({ style: 4 });

    // changeStyleOrder();
    // layoutStateService.get$('style').subscribe(() => {
    //   changeStyleOrder();
    // });
  };
}

// function changeStyleOrder() {
//   const target = document.querySelector<HTMLElement>('style#lepton-styles');
//   const leptonStyle = document.querySelector<HTMLElement>('link[href^=lepton]');
//   const strategy = DOM_STRATEGY.BeforeElement(target);
//   strategy.insertElement(leptonStyle);
// }

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

export function isWatchEnvironment() {
  return getBaseUrl().indexOf("pstcomplete.local") >= 0;
}

function setInitialStyle(configStateService: ConfigStateService) {
  return () => {
    // let appConfig = configStateService.getSetting('')

    // configStateService.setState({ initialStyle: 'lepton' })
  };
}
