import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { SettingDefinitionDto, SettingEntryDto } from '../application-settings/models';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  apiName = 'Default';
  

  createOrUpdateSettingEntryByCreateSettingEntry = (createSettingEntry: SettingEntryDto[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/settings',
      body: createSettingEntry,
    },
    { apiName: this.apiName,...config });
  

  getAgentUpdateSettingValues = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, SettingDefinitionDto[]>({
      method: 'GET',
      url: '/api/app/settings/agent-update-settings',
    },
    { apiName: this.apiName,...config });
  

  getPSTScanSettings = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, SettingDefinitionDto[]>({
      method: 'GET',
      url: '/api/app/settings/pst-settings',
    },
    { apiName: this.apiName,...config });
  

  getSMTPSettingsDefaultValues = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, SettingDefinitionDto[]>({
      method: 'GET',
      url: '/api/app/settings',
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
