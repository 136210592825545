import { CoreModule } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgModule } from '@angular/core';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { SavedViewsComponent } from './saved-views/saved-views.component';
import { SaveViewComponent } from './save-view/save-view.component';

@NgModule({
  declarations: [
    SavedViewsComponent,
    SaveViewComponent,
  ],
  imports: [
    CoreModule,
    ThemeSharedModule,
    CommercialUiModule,
    NgxValidateCoreModule,
  ],
  exports: [
    SavedViewsComponent,
    SaveViewComponent,
  ]
})
export class ViewsModule {}
