<abp-modal [busy]="isModalBusy" [(visible)]="isViewModalOpen">
  <ng-template #abpHeader>
    <h3>
      {{ '::SaveCurrentView' | abpLocalization }}
    </h3>
  </ng-template>
  <ng-template #abpBody>
    <form [formGroup]="viewForm" (ngSubmit)="submitView()" validateOnSubmit>
      <div class="mt-2 fade-in-top">
        <div class="form-group mb-3">
          <label class="form-label" for="aadtenant-view-name">
            {{ '::ViewName' | abpLocalization }}
          </label>
          <input
            id="aadtenant-view-name"
            formControlName="name"
            class="form-control"
            autofocus
          />
        </div>
        <div class="form-group mb-3">
          <label class="form-label" for="view-permission">{{ '::ViewPermission' | abpLocalization }}</label>
          <select class="form-control" formControlName="private" id="view-permission">
            <option value="true">Private</option>
            <option value="false">Public</option>
          </select>
        </div>
        <div hidden="hidden" class="form-group mb-3">
          <label class="form-label" for="content">
            {{ '::Content' | abpLocalization }}
          </label>
          <input
            id="aadtenant-content"
            formControlName="content"
            class="form-control"
          />
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>
    <abp-button iconClass="fa fa-check" (click)="submitView()" [disabled]="viewForm?.invalid">
      {{ 'AbpUi::Save' | abpLocalization }}
    </abp-button>
  </ng-template>
</abp-modal>
