import { Component } from '@angular/core';
import {Location} from '@angular/common';

@Component({
    selector: 'custom-error',
    templateUrl: './custom-error-component.html'
})
export class CustomErrorComponent {

    constructor(private _location: Location) {}

    errorStatus: string;

    backClicked() {
        this._location.back();
    }
}
