<div
  _ngcontent-mnx-c154=""
  id="abp-http-error-container"
  class="error"
  style="background-color: rgb(54, 69, 90)"
>
  <div class="error-page-container">
    <div class="row">
      <div class="col col-auto">
        <div class="status-icon">
          <i aria-hidden="true" class="fa fa-frown-o text-danger"></i><span></span>
        </div>
      </div>
      <div class="col">
        <div class="status-content">
          <h1>{{ errorStatus }}</h1>
          <h2 class="text-danger mb-0"><small class="text-muted"></small></h2>
          <p class="mt-3 mb-4"></p>
          <a routerlink="/" class="btn btn-primary" ng-reflect-router-link="/" href="/"
            >{{ '::GoHomePage' | abpLocalization}}</a
          ><a (click) = "backClicked()" class="mt-4 mb-1 d-block"
            ><i class="fa fa-long-arrow-left me-1"></i>{{ '::GoBack' | abpLocalization }}</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
