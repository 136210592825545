import { ListService } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { SavedViewsService } from '@proxy/uisaved-views';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { EntityName } from '@proxy/ui-saved-views/enums/entity-name.enum';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize, tap } from 'rxjs/operators';
import { ConfirmationService, DateAdapter } from '@abp/ng.theme.shared';

@Component({
  selector: 'save-view-modal',
  providers: [ListService, {provide: NgbDateAdapter, useClass: DateAdapter}],
  templateUrl: './save-view.component.html',
  styleUrls: []
})
export class SaveViewComponent implements OnInit {
  isViewModalOpen = false;
  isModalBusy = false;
  viewForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder,
              private service: SavedViewsService,
              private confirmation: ConfirmationService,) {
  }

  ngOnInit(): void {
    if(this.viewForm){
      this.viewForm.reset();
    }

  }

  openModal(pageName: EntityName,content?:string) {
    this.viewForm = this.fb.group({
      entity: [pageName, [Validators.required]],
      name: ['', [Validators.required]],
      private: [false, [Validators.required]],
      content:[content,[Validators.required]]
    });
    this.isViewModalOpen = true;
  }

  hideForm() {
    this.isViewModalOpen = false;
    this.viewForm.reset();
  }

  submitView() {
    if (this.viewForm.invalid) return;
    this.service.saveViewBySaveViews(this.viewForm.value)
      .pipe(
        finalize(() => (this.isModalBusy = false)),
        tap(() => this.hideForm()),
      )
      .subscribe(result => {

      }, error => {
        this.confirmation.error('::DuplicateViewName', '::Error', {
          hideCancelBtn: false,
          hideYesBtn: true,
          cancelText: 'Close',
        });
      });
  }

}
