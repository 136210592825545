import { ABP, ListService, PagedResultDto } from '@abp/ng.core';
import { Component, EventEmitter, Output } from '@angular/core';
import { SavedViewsService, GetSavedViewsInput, SavedViewsDto } from '@proxy/uisaved-views';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { Confirmation, ConfirmationService, DateAdapter } from '@abp/ng.theme.shared';
import { EntityName } from '@proxy/ui-saved-views/enums/entity-name.enum';
import { filter, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-saved-views',
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
  templateUrl: './saved-views.component.html',
  styleUrls: ['./saved-views.component.scss']
})
export class SavedViewsComponent {
  data: PagedResultDto<SavedViewsDto> = {
    items: [],
    totalCount: 0,
  };
  totalCount = 0;
  isRestoreView: boolean = false;
  selectedView = {} as SavedViewsDto;
  filters = {} as GetSavedViewsInput;
  isDisabled = true;
  @Output() content = new EventEmitter<string>();

  constructor(private list: ListService,
    private service: SavedViewsService,
    private confirmation: ConfirmationService) {
  }

  async openModal(pageName: EntityName) {
    this.isDisabled = true;
    this.filters.entity = pageName;
    const getData = (query: ABP.PageQueryParams) =>
      this.service.getList({
        ...query,
        ...this.filters,
      });

    const setData = (list: PagedResultDto<SavedViewsDto>) => (this.data = list);
    this.list.hookToQuery(getData).subscribe(setData);
    this.isRestoreView = true;
  }

  loadViews() {
    if (Object.keys(this.selectedView).length > 0) {
      this.isRestoreView = false;
      this.content.emit(this.selectedView.content);
      this.isDisabled = false;
    }

  }

  deleteView(id) {
    this.confirmation.warn(
      '::DeleteConfirmationMessage',
      '::AreYouSure',
      { messageLocalizationParams: [] }
    ).pipe(
      filter(status => status === Confirmation.Status.confirm),
      switchMap(() => this.service.deleteViewById(id)),
    ).subscribe(this.list.get);
  }
  selectRow(event, row) {
    if (event.target.value == 'on') {
      this.selectedView = row;
      this.isDisabled = false;
    }
  }
}
